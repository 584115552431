/* You can add global styles to this file, and also import other style files */

.dropdown-menu {
    background-color: #040b47;
    color: white;
    margin: 0em;
    padding: 0%;
    border: 1px solid white;
}

.dropdown-item {
  height: 40;
  color: white;
  padding: 0.5em;
}

.ngb-carousel {
    max-height: 200px;
}

@import "~bootstrap/dist/css/bootstrap.css"

